import { useMemo, type HTMLAttributes } from "react";
import { icons } from "./icons";
import { cn } from "@/lib/cn";

export type IconName = keyof typeof icons;

type Props = HTMLAttributes<HTMLDivElement> & {
	icon: IconName;
};

/**
 * How to add a new icon:
 * 1. Export icon as SVG from Figma (stroke: 1.5, radius: 0, filled: false, join: square)
 * 2. Rename it properly
 * 3.1. Drop icons into `/icons`. They will be optimized and some attributes will change
 * 3.2. Drop logos or icons with multiple colors into `/logos`. They will remain untouched. Try to optimize before with: https://jakearchibald.github.io/svgomg/
 * 4. Run `pnpm generate:icons`
 * 5. Ready to use!
 *
 * See idea explained here: https://medium.com/@mateuszpalka/creating-your-custom-svg-icon-library-in-react-a5ff1c4c704a
 */

/**
 * Displays an icon. Use CSS/Tailwind `text-color` to change icon color. If an icon is inside an element with interactions (e.g. in a button) that doesn't have text in it, remember to add an aria-label
 * @param icon string key icon name
 * @param label add label to icon to make it accessible
 * @returns Icon react component
 */
export function Icon({ icon, className, ...props }: Props) {
	const SvgIcon = useMemo(() => icons[icon], [icon]);

	return (
		<div
			className={cn("flex items-center justify-center text-foreground [&>svg]:h-full [&>svg]:w-auto", className)}
			{...props}
		>
			<SvgIcon />
		</div>
	);
}
