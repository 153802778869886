"use client";

import { Controller, FormProvider, type FieldValues, type FormProviderProps, useFormContext } from "react-hook-form";

import { Input, type InputProps } from "./input";
import { Checkbox, type CheckboxProps } from "./checkbox";
import { Select, type SelectProps } from "./select";
import { type RadioGroupProps } from "@radix-ui/react-radio-group";
import { RadioGroup } from "./radio-group";
import { createContext, useContext } from "react";
import { Button } from "./button";

type FormSettings = {
	keepLoadingAfterSuccessfulSubmit?: boolean;
};

const FormSettingsContext = createContext<FormSettings>({} as FormSettings);

/**
 * Form component that wraps react-hook-form's FormProvider and provides a context for settings.
 * Available settings:
 * @param keepLoadingAfterSuccessfulSubmit Useful for forms that redirect after a successful submit. Keeps the inputs disabled and buttons loading even after the form is submitted.
 * A submit is considered successful if the submit function finishes and the form has no errors. (e.g. set with setError).
 * **Note that a throwing submit function will still be considered successful if no error is set.**
 * @returns
 */
function Form<
	TFieldValues extends FieldValues = FieldValues,
	TContext = unknown,
	TTransformedValues extends FieldValues | undefined = undefined,
>({
	keepLoadingAfterSuccessfulSubmit,
	...props
}: FormProviderProps<TFieldValues, TContext, TTransformedValues> & FormSettings) {
	return (
		<FormSettingsContext.Provider value={{ keepLoadingAfterSuccessfulSubmit }}>
			<FormProvider {...props} />
		</FormSettingsContext.Provider>
	);
}

const FormInput = ({ placeholder, name, ...props }: Omit<InputProps, "error"> & { name: string }) => {
	const { keepLoadingAfterSuccessfulSubmit } = useContext(FormSettingsContext);
	return (
		<Controller
			name={name}
			render={({ field, fieldState, formState }) => (
				<Input
					id={name} // required to get values in paypal `createOrder`
					placeholder={placeholder}
					error={fieldState.error?.message}
					{...field}
					{...props}
					disabled={
						formState.isSubmitting ||
						!!props.disabled ||
						!!field.disabled ||
						(keepLoadingAfterSuccessfulSubmit && formState.isSubmitSuccessful)
					}
				/>
			)}
		/>
	);
};
FormInput.displayName = "FormInput";

/**
 * Accepts a groupValue prop to use the checkbox as part of an array field.
 * If groupValue is set, the field value corresponding to the name prop must be an array.
 * The checkbox will be checked if the groupValue is included in the array and will add or remove the groupValue from the array when checked or unchecked.
 * @example
 *
 * useForm({
 *  defaultValues: {
 *   favoriteArtists: ["kraftklub", "kiz"]
 * });
 *
 * <FormCheckbox name="favoriteArtists" groupValue="kraftklub"/> // checked
 * <FormCheckbox name="favoriteArtists" groupValue="kiz"/> // checked
 * <FormCheckbox name="favoriteArtists" groupValue="casper"/> // not checked, will add "casper" to the array when checked
 */
const FormCheckbox = ({
	name,
	groupValue,
	...props
}: Omit<CheckboxProps, "error"> & { name: string; groupValue?: string }) => {
	const { keepLoadingAfterSuccessfulSubmit } = useContext(FormSettingsContext);

	return (
		<Controller
			name={name}
			render={({ field, fieldState, formState }) => {
				if (groupValue && !Array.isArray(field.value)) {
					console.error(
						`When using the groupValue prop, the field value must be an array. Received:`,
						field.value
					);
					return <>Could not render checkbox.</>;
				}

				const value = (field.value as string[] | undefined) ?? [];

				const propsDependingOnGroup: CheckboxProps = groupValue
					? {
							checked: value.includes(groupValue),
							onCheckedChange: (checked) => {
								checked
									? field.onChange([...value, groupValue])
									: field.onChange(value.filter((value) => value !== groupValue));
							},
						}
					: {
							checked: Boolean(field.value),
							onCheckedChange: field.onChange,
						};

				return (
					<Checkbox
						error={fieldState.error?.message}
						{...propsDependingOnGroup}
						{...props}
						{...field}
						disabled={
							formState.isSubmitting ||
							!!props.disabled ||
							!!field.disabled ||
							(keepLoadingAfterSuccessfulSubmit && formState.isSubmitSuccessful)
						}
					/>
				);
			}}
		/>
	);
};
FormCheckbox.displayName = "FormCheckbox";

const FormSelect = ({ name, ...props }: Omit<SelectProps, "error"> & { name: string }) => {
	const { keepLoadingAfterSuccessfulSubmit } = useContext(FormSettingsContext);
	return (
		<Controller
			name={name}
			render={({ field, formState }) => (
				<Select
					{...field}
					{...props}
					aria-disabled={
						formState.isSubmitting ||
						!!props["aria-disabled"] ||
						!!field.disabled ||
						(keepLoadingAfterSuccessfulSubmit && formState.isSubmitSuccessful)
					}
				/>
			)}
		/>
	);
};

const FormRadioGroup = ({
	name,
	children,
	...props
}: Omit<RadioGroupProps, "error" | "onValueChange" | "defaultValue"> & { name: string }) => {
	const { keepLoadingAfterSuccessfulSubmit } = useContext(FormSettingsContext);
	return (
		<Controller
			name={name}
			render={({ field, fieldState, formState }) => (
				<RadioGroup
					onValueChange={field.onChange}
					defaultValue={field.value as string}
					value={field.value as string}
					aria-errormessage={fieldState.error?.message}
					aria-invalid={Boolean(fieldState.error)}
					{...props}
					disabled={
						formState.isSubmitting ||
						!!props.disabled ||
						!!field.disabled ||
						(keepLoadingAfterSuccessfulSubmit && formState.isSubmitSuccessful)
					}
				>
					{children}
				</RadioGroup>
			)}
		/>
	);
};

export const useFormLoading = () => {
	const { keepLoadingAfterSuccessfulSubmit } = useContext(FormSettingsContext);
	const { formState } = useFormContext();
	return formState.isSubmitting || (keepLoadingAfterSuccessfulSubmit && formState.isSubmitSuccessful);
};

/**
 * Wrapper around Button that automatically sets the loading state based on the form state.
 * @see {@link Button}
 */
const FormSubmit = ({ loading, ...props }: React.ComponentProps<typeof Button>) => {
	const formLoading = useFormLoading();
	return <Button type="submit" loading={!!loading || formLoading} {...props} />;
};
export { FormSubmit, Form, FormInput, FormCheckbox, FormSelect, FormRadioGroup };
