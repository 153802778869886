import { twx } from "@/lib/cn";
import { type VariantProps, cva } from "class-variance-authority";
import { type TwcComponentProps } from "react-twc";

const variants = cva("", {
	variants: {
		size: {
			xs: "text-xs",
			sm: "text-sm",
			base: "text-base",
			lg: "text-base md:text-lg",
			xl: "text-xl",
			"2xl": "text-2xl md:text-3xl",
			"3xl": "text-3xl md:text-4xl",
		},
		weight: {
			regular: "font-normal",
			medium: "font-medium",
			semibold: "font-semibold",
			bold: "font-bold",
		},
	},
	defaultVariants: {
		size: "base",
		weight: "regular",
	},
});

export type TextProps = TwcComponentProps<"p"> & VariantProps<typeof variants>;

/**
 * KS text component.
 *
 * @example
 * <Text size="3xl" weight="medium" asChild>
 *   <h1>Header 1</h1>
 * </Text>
 *
 * @remarks
 * For `3xl` and `2xl` sizes, the text will be responsive and scale up on larger screens.
 * @param {string} [size="base"] - The size of the text. Defaults to `base`.
 * @param {string} [weight="regular"] - The weight of the text. Defaults to `regular`.
 * @params All other props are passed to the element.
 */
const Text = twx.span.transientProps(["size", "weight"])<TextProps>(({ size, weight }) => variants({ size, weight }));
Text.displayName = "Text";

export { Text };
