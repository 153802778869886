import { type VariantProps, cva } from "class-variance-authority";
import { twx } from "@/lib/cn";
import { type TwcComponentProps } from "react-twc";
const selectVariants = cva(
	"rounded-lg bg-transparent pl-4 pr-6 py-3 text-base font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 aria-disabled:opacity-50 aria-disabled:cursor-not-allowed aria-disabled:pointer-events-none",
	{
		variants: {
			variant: {
				outline: "border border-primary hover:bg-gray-600",
			},
		},
		defaultVariants: {
			variant: "outline",
		},
	}
);

export type SelectProps = Omit<TwcComponentProps<"select">, "disabled"> & VariantProps<typeof selectVariants>;

/**
 * KS select component.
 *
 * @example
 * <Select state="disabled" onClick={handleClick}>
 *   Click me
 * </Select>
 *
 * @remarks
 * The `disabled` prop can't be used directly in this component because it can affect accessibility of the select. Instead, use the `aria-disabled` prop.
 * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-disabled for more information.
 *
 * @params All other props are passed to the select element.
 */
const Select = twx.select.transientProps(["variant"])<SelectProps>(({ variant }) => selectVariants({ variant }));
Select.displayName = "Select";

export { Select, selectVariants };
