"use client";

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "@/lib/cn";
import { Icon } from "./Icon/Icon";

export type CheckboxProps = {
	error?: string;
} & React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>;

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
	({ className, children, error, ...props }, ref) => (
		<div className="">
			<label
				className={cn(
					"flex cursor-pointer gap-2 text-base leading-none",
					props.disabled && "cursor-not-allowed opacity-70"
				)}
			>
				<CheckboxPrimitive.Root
					ref={ref}
					className={cn(
						"group peer relative size-4 shrink-0 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
						className
					)}
					{...props}
				>
					<div
						className={cn(
							"absolute inset-0 rounded-[4px] border border-primary",
							error && "border-red-light"
						)}
					/>
					<Icon
						icon="checkbox-unchecked"
						className="absolute inset-0 size-full opacity-0 transition-all duration-200 group-data-[state='unchecked']:group-hover:opacity-100"
					/>
					<CheckboxPrimitive.Indicator className={cn("flex items-center justify-center")} asChild>
						<>
							<Icon icon="checkbox-checked" className="size-full" />
						</>
					</CheckboxPrimitive.Indicator>
				</CheckboxPrimitive.Root>
				<div>{children}</div>
			</label>
		</div>
	)
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
