"use client";

import { CheckIcon } from "@radix-ui/react-icons";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { cn } from "@/lib/cn";
import { forwardRef, useId } from "react";

const RadioGroup = forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
	return <RadioGroupPrimitive.Root className={cn("grid gap-2", className)} {...props} ref={ref} />;
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
		collapsible?: React.ReactNode;
		centerIndicator?: boolean;
	}
>(({ className, id: customId, children, collapsible, centerIndicator, ...props }, ref) => {
	// we require a unique id to connect the input and label
	// useId generates an id thats consistent between renders and server/client which is used if no custom id is provided
	const generatedId = useId();
	const id = customId ?? generatedId;
	return (
		<RadioGroupPrimitive.Item id={id} ref={ref} className={cn("group flex flex-col", className)} {...props}>
			<div className={cn("flex w-full gap-4", centerIndicator && "items-center")}>
				<span className="aspect-square size-4 rounded-full border text-primary shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 group-aria-checked:border-primary group-aria-checked:bg-primary">
					<RadioGroupPrimitive.Indicator className="flex items-center justify-center">
						<CheckIcon className="fill-primary-foreground invert" />
					</RadioGroupPrimitive.Indicator>
				</span>
				<label
					htmlFor={id}
					className="w-full cursor-pointer text-base leading-none group-disabled:cursor-not-allowed group-disabled:opacity-70"
				>
					{children}
				</label>
			</div>
			{collapsible ? (
				<div className="hidden h-0 w-full overflow-hidden pt-4 transition-[height] ease-out will-change-[height] group-aria-checked:block group-aria-checked:h-auto group-aria-checked:overflow-visible">
					{collapsible}
				</div>
			) : null}
		</RadioGroupPrimitive.Item>
	);
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
