import dynamic from "next/dynamic";
export const icons = {
	"arrow-down": dynamic(async () => import("./icons/arrow-down.svg")),
	"arrow-left": dynamic(async () => import("./icons/arrow-left.svg")),
	"arrow-right": dynamic(async () => import("./icons/arrow-right.svg")),
	"arrow-undo-up-stroke-1": dynamic(async () => import("./icons/arrow-undo-up-stroke-1.svg")),
	"arrow-up": dynamic(async () => import("./icons/arrow-up.svg")),
	bank: dynamic(async () => import("./icons/bank.svg")),
	"bubble-info-stroke-1": dynamic(async () => import("./icons/bubble-info-stroke-1.svg")),
	"calendar-add": dynamic(async () => import("./icons/calendar-add.svg")),
	"calendar-days": dynamic(async () => import("./icons/calendar-days.svg")),
	"calender-remove-stroke-1": dynamic(async () => import("./icons/calender-remove-stroke-1.svg")),
	"checkbox-checked": dynamic(async () => import("./icons/checkbox-checked.svg")),
	"checkbox-unchecked": dynamic(async () => import("./icons/checkbox-unchecked.svg")),
	"chevron-down-small": dynamic(async () => import("./icons/chevron-down-small.svg")),
	"circle-check": dynamic(async () => import("./icons/circle-check.svg")),
	circle: dynamic(async () => import("./icons/circle.svg")),
	close: dynamic(async () => import("./icons/close.svg")),
	"envelope-stroke-1": dynamic(async () => import("./icons/envelope-stroke-1.svg")),
	"file-download-stroke-1": dynamic(async () => import("./icons/file-download-stroke-1.svg")),
	"hard-ticket": dynamic(async () => import("./icons/hard-ticket.svg")),
	"info-circle": dynamic(async () => import("./icons/info-circle.svg")),
	kulturpass: dynamic(async () => import("./icons/kulturpass.svg")),
	"minus-large": dynamic(async () => import("./icons/minus-large.svg")),
	"online-ticket": dynamic(async () => import("./icons/online-ticket.svg")),
	"package-delivery-stroke-1": dynamic(async () => import("./icons/package-delivery-stroke-1.svg")),
	"plus-large": dynamic(async () => import("./icons/plus-large.svg")),
	"logos/apple-pay": dynamic(async () => import("./logos/apple-pay.svg")),
	"logos/google-pay": dynamic(async () => import("./logos/google-pay.svg")),
	"logos/mastercard": dynamic(async () => import("./logos/mastercard.svg")),
	"logos/paypal": dynamic(async () => import("./logos/paypal.svg")),
	"logos/sofort": dynamic(async () => import("./logos/sofort.svg")),
	"logos/visa": dynamic(async () => import("./logos/visa.svg")),
};
