import { cn } from "@/lib/cn";
import { forwardRef, useId, type ReactNode } from "react";
import { Button, type ButtonProps } from "./button";

export type InputProps = {
	error?: string;
	inputClasses?: string;
	inverted?: boolean;
	inlineButton?: {
		label: string | ReactNode; // e.g. <Icon icon="arrow-right" className="size-4 text-white" />
		props?: ButtonProps;
	};
	reserveErrorSpace?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			inputClasses,
			placeholder,
			inverted,
			inlineButton,
			error,
			reserveErrorSpace,
			id: customId,
			...props
		},
		ref
	) => {
		// we require a unique id to connect the input and label
		// useId generates an id thats consistent between renders and server/client which is used if no custom id is provided
		const generatedId = useId();
		const id = customId ?? generatedId;

		return (
			<div className={cn(className, reserveErrorSpace && "min-h-[3.75rem]")}>
				<div className="relative">
					<div className="flex w-full items-stretch">
						<input
							aria-invalid={!!error}
							aria-describedby={error ? `${id}-error` : undefined}
							id={id}
							className={cn(
								"peer block grow appearance-none rounded-lg border border-gray-700 bg-transparent px-4 pb-2 pt-4 text-lg text-black placeholder-shown:py-3 focus:border-black focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50",
								error && "border-red-light focus:border-red-light",
								inverted && "border-black bg-black text-white",
								inlineButton && "rounded-r-none",
								inputClasses
							)}
							ref={ref}
							placeholder=" " // required to use `placeholder-shown` pseudo-class
							{...props}
						/>
						<label
							htmlFor={id}
							className={cn(
								`absolute start-1 top-2 origin-[0] -translate-y-1 scale-75 px-4 text-base text-gray-800 duration-300 peer-placeholder-shown:pointer-events-none peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100`,
								inverted && "text-white"
							)}
						>
							{placeholder}
						</label>
						{inlineButton && (
							<Button className="h-auto rounded-l-none" {...inlineButton.props}>
								{inlineButton.label}
							</Button>
						)}
					</div>
				</div>
				{error && (
					<p id={`${id}-error`} className="pt-[2px] text-xs text-red-light">
						{error}
					</p>
				)}
			</div>
		);
	}
);
Input.displayName = "Input";

export { Input };
